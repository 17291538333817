import locationpreprocessorServices from '../../../../services/notisphere/locationpreprocessor'
import DocumentViewModel from '../../../../components/part/shared/DocumentViewModel'
import { LOCATION_PRE_PROCESSOR_STATUS} from '../../../../misc/commons'
export default {
    props: {
        id: {type: String, default: null}
    },
    components: {
        DocumentViewModel
    },
    data() {
        return {
            item: null,
            tabDetails: true,
            tabHistory: false,
            locationPreProcessor_status: LOCATION_PRE_PROCESSOR_STATUS,
        }
    },
    created() {
        this.getLocationPreProcessorJobById()
    },
    methods: {
        getLocationPreProcessorJobById() {
            locationpreprocessorServices.getLocationPreProcessorJobById(this.id).then(resp => {
                if (resp.error){
                    this.$router.push({ name: 'noti_Location_preProcessor' })
                } else {
                    this.item = resp.data.d
                }
            })
        }

    }
}